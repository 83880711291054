import _ from "lodash"

export function parseFormSubjects(form) {
  if (form.subjects == undefined || form.subjects === null) {
    return [];
  }

  return form.subjects.sort((a, b) => a.order - b.order).map((subject) => {
    let config = subject.config;

    return {
      ...config,
      id: subject.id,
      type: subject.type,
    }
  });
}

export function subjectTypeIcon(type) {
  const iconMapping = {
    text: 'mdi-format-title',
    textarea: 'mdi-format-size',
    radio: 'mdi-radiobox-marked',
    checkbox: 'mdi-checkbox-marked-outline',
    select: 'mdi-chevron-down',
    datetime: 'mdi-clock',
    uploader: 'mdi-upload',
    collection_select: 'mdi-file-tree',
    collection_carousel: 'mdi-file-tree',
    shop_selection: 'mdi-domain',
    section_page: 'mdi-note-plus-outline',
    
  }
  return _.get(iconMapping, type, 'mdi-format-title')
}
