<template>
  <div class="is-loading" v-if="isFetchingData">
    <b-spinner style="border-width: 2px" />
  </div>
  <div v-else>
    <div v-if="styleType === 'unlimited'">
      <vue-timepicker
        :placeholder="placeholder"
        format="HH:mm"
        v-model="time"
        hour-label="時"
        minute-label="分"
        :input-class="[ 'form-control' ]"
        class="app-time-picker"
        :class="{ 'app-time-picker--is-invalid': hasError }"
        @input="handleInput"
        :disabled="disabled"
      ></vue-timepicker>
    </div>
    <div v-else>
      <template v-if="timeStyle == 'select_style'">
        <div class="dynamic-select">
          <b-form-select
            v-model="time"
            @input="handleInput"
            :disabled="disabled"
          >
            <b-form-select-option :value="null">{{ placeholder }}</b-form-select-option>
            <b-form-select-option
              v-for="timeOption in timeOptions"
              :key="timeOption"
              :value="timeOption"
            >
              {{ timeOption }}
            </b-form-select-option>
          </b-form-select>
        </div>
      </template>
      <template v-else-if="timeStyle == 'button_style'">
        <div class="timer">
          <div
            v-for="timeOption in timeOptions"
            :key="timeOption"
            class="time-option"
            :class="{ 'selected': time === timeOption }"
            @click="pickTime(timeOption)"
          >
            <input
              type="radio"
              :id="timeOption"
              :value="timeOption"
              v-model="time"
              class="time-input"
              :disabled="disabled"
              />
            <label :for="timeOption">{{ timeOption }}</label>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import parseHourAndMinute from "@/utils/parseHourAndMinute";
import deepGet from "lodash/get";
import VueTimepicker from "vue2-timepicker";

export default {
  props: {
    value: [Date, String],
    placeholder: {
      type: String,
      default: ' ',
    },
    timeStyle: {
      type: String,
      default: 'select_style',
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    styleType: {
      type: String,
      default: 'unlimited',
    },
    startTime: {
      type: String,
      default: '00:00',
    },
    endTime: {
      type: String,
      default: '21:00',
    },
    slotMinutes: {
      type: [String, Number],
      default: "30",
    },
    isFetchingData: {
      type: Boolean,
      default: false,
    },
    diabled: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    VueTimepicker,
  },
  data: () => ({
    time: {
      HH: null,
      mm: null,
    },
  }),
  watch: {
    value(val) {
      if (this.styleType == 'limited') {
        try {
          const [hour, minute] = parseHourAndMinute(val)
          this.time = `${hour}:${minute}`
        } catch (e) {
          console.warn('[AppTimePicker] Received invalid time format:', val)
        }
        return
      }
      this.formatValueFromInput(val)
    },
  },
  mounted() {
    if (this.styleType == 'limited') {
      this.time = this.value
      return
    }
    this.formatValueFromInput(this.value)
  },
  computed: {
    timeOptions() {
      if (!this.startTime || !this.endTime) {
        return [];
      }

      let options = [];
      const slotMinutes = Number(this.slotMinutes);
      if (slotMinutes < 1) {
        return [];
      }

      // 將時間轉換為分鐘
      const timeToMinutes = (time) => {
        if (!time) {
          return 0;
        }
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
      };

      // 將分鐘轉回時間格式 "HH:MM"
      const minutesToTime = (minutes) => {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
      };

      const startMinutes = timeToMinutes(this.startTime);
      const endMinutes = timeToMinutes(this.endTime);

      // 計算實際的最後服務時間
      const lastServeMinutes = timeToMinutes(this.endTime);
      const adjustedLastServeMinutes = lastServeMinutes - Number(0);

      // 確保不會超過 24:00
      const actualEndMinutes = Math.min(endMinutes, adjustedLastServeMinutes);

      let currentMinutes = startMinutes;

      while (currentMinutes <= actualEndMinutes) {
        options.push(minutesToTime(currentMinutes));
        currentMinutes += slotMinutes;

        // 防止超過一天的範圍（1440 分鐘）
        if (currentMinutes >= 1440) {
          break;
        }
      }

      return options;
  }

  },
  methods: {
    deepGet,
    formatValueFromInput(value) {
      if ([null, undefined].includes(value)) {
        // catched but do nothing, can be altered to provide default value
      } else {
        try {
          const [hour, minute] = parseHourAndMinute(value)
          this.time = { HH: hour, mm: minute }
        } catch (e) {
          console.warn('[AppTimePicker] Received invalid time format:', value)
        }
      }
    },
    handleInput(value) {
      // options
      if (this.styleType == 'limited') {
        this.$emit('input', value)
        return
      }
      // picker
      if (value.HH && value.mm) {
        this.$emit('input', `${value.HH}:${value.mm}`)
      }

      if (!value.HH && !value.mm) {
        this.$emit('input', null)
      }
    },
    pickTime(value) {
      this.time = value;
      this.handleInput(value)
    }
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/scss/base-colors.scss";

::v-deep.app-time-picker {
  .form-control {
    border-radius: 5px;
    font-size: inherit;
  }

  &--is-invalid {
    .form-control {
      border: 1px solid map-get(base-colors.$theme-colors, danger);
    }
  }
}

.timer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.time-input {
  display: none;
}

.time-option {
  border: 1px solid #E5E5EA;
  width: calc((100% - 16px) / 3);
  text-align: center;
  font-weight: 500;
  padding: 0 30px;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
  height: 36px;
  line-height: 32px;
  &.selected {
    border: 3px solid var(--s-primary);
  }
}

.dynamic-select {
  position: relative;
  font-family: "PingFang TC";
  font-style: normal;
  font-weight: 600;
  width: 100%;

  select {
    padding: 0px 1rem;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    font-size: 16px;
    border: dotted 1px var(--liff-button-color) !important;
    border-radius: 3px;

    .dynamic-select--border & {
      border: 1px solid #636366;
      border-radius: 10px;
      padding: 16px 12px;
      &:focus {
        border-bottom-color: #636366;
      }
    }
  }

  &__caret {
    position: absolute;
    right: 20px;
    width: 12px;
    bottom: 30%;
  }

  &--border {
    select {
      border: 1px solid #636366;
      border-radius: 10px;
      padding: 16px 12px;
      &:focus {
        border-bottom-color: #636366;
      }
    }
  }

  &.invalid {
      border: 1px solid #fe0000;
    }
}

.is-loading {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: unset;
}
</style>
